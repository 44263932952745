<template>
    <!-- 
        Список запитів на відгулів
        - дозволяє переглянути список відгулів з фільтрацією по співробітнику та періоду
        - відкрити картку відгулу
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- Кнопка "Скачування документу" -->
    <div>
        <button 
            class="btn btn-info" 
            v-if="this.perms[2000] === true || this.user.userid === 111"
            @click="this.showDatePicker = true"
            style="margin-bottom: 20px;"
        >
            <i class='bx bx-download'></i>
            {{ $t("Download") + " " + $t("statReport") }}
        </button>
    </div>

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки відгулу
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams" 
        :objParams="objParams" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages" 
        :showpag="true" 
        @open="open" 
        @getdata="getdata" 
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
       Виклик відкриття картки відгулу
       - для відкриття застосовується перевірка значення showModal
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними про відгулу
       - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
    -->
    <viewBox 
        v-if="showModal" 
        @close="closeModal" 
        :obj="objCard" 
        @getdata="getdata" 
    />

    <!-- Модалка дат -->
    <dialogModal v-if="showDatePicker" @close="this.showDatePicker = false">
        <template v-slot:title>{{ $t('Download') }}</template>
        <template v-slot:body>
            <div class="mt-4 text-center row">
                <div class="col-md-6">
                    <!-- Інпути для дат -->
                    <input 
                        v-model="this.excelForm.pr1"
                        type="date" 
                        class="form-control col-md-6"
                        min="2024-01-01"
                    >
                </div>
                <div class="col-md-6">
                    <input 
                        v-model="this.excelForm.pr2"
                        type="date" 
                        class="form-control col-md-6"
                        min="2024-01-01"
                    >
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка скачування -->
                <button 
                    class="btn btn-success" 
                    @click="downloadExcel"
                >
                    {{ $t('Download') }}
                </button>
                <button 
                    class="btn btn-danger" 
                    @click="showDatePicker = false"
                >
                    {{ $t('close') }}
                </button>
            </div>
        </template>
    </dialogModal>

</template>

<script>
import dialogModal from "@/components/modal/dialog.vue"
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewBox from './view.vue' // картка відгулу
import { Holidays } from '@/API' // клас для взаємодії з API 
import { storeS } from "@/store"; // глобальне сховище даних
import { mutateTypeHoliday, mutateStatusHoliday , mutateReasonHoliday } from '@/usabilityScripts/globalMutate.js'
import axios from "axios";

let apiServe = new Holidays();

export default {
    components: {
        dialogModal,
        PageHeader,
        tablecustom,
        viewBox
    },
    data() {
        return {
            showDatePicker: false,
            title: this.$t('timeOff'), // Заголовок
            showModal: false, // Прапорець відображення вікна відгулу
            objCard: {}, // Об'єкт з даними відгулу для відображення в модальному вікні
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "holidays"
            },
            excelForm: {
                pr1: '',
                pr2: '',
                companyId: '',
                token: ''
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                // search: '',
                workerId: '',
                pr1: '',
                pr2: '',
                of: 'date',
                ot: 'desc',
                status: '',
            },
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "holidayId",
                    align: "left",
                    status: false,
                },
                {
                    name: this.$t('name'),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('type'),
                    text: "type",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.mutateType(item, obj)
                },
                {
                    name: this.$t('Cause'),
                    text: "reason",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateReason(item)
                },
                {
                    name: this.$t('Status'),
                    text: "status",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color
                },
                {
                    name: this.$t('Comment') + " (" + this.$t('confirmation') + "/" + this.$t('Refusal') + ")",
                    text: "confirmComment",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => obj.confirmComment == null && obj.cancelComment == null ? '-' : obj.confirmComment ? obj.confirmComment : obj.cancelComment
                },
                {
                    name: this.$t('date'),
                    text: "date",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => obj.toDate != null ? obj.date + " - " + obj.toDate : item
                },
                {
                    name: this.$t('Comment'),
                    text: "comment",
                    align: "left",
                    status: true,
                },
            ],
            rows: [], // Дані для відображення у таблиці
        }
    },
    created() {
        this.getdata();
        // Дефолтні дані
        [this.excelForm.token, this.excelForm.companyId] = 
        [storeS.savedTokenAndCompany.replace('token=', '').split('&companyId=')[0], 
        storeS.savedTokenAndCompany.split('&companyId=')[1]]
    },
    methods: {
        // Скачка файлу Excel
        downloadExcel() {
            // Сам запит для отримання Excel
            if(this.excelForm.pr1 != '' && this.excelForm.pr2 != '') {
                const fetchData = async () => {
                    try{   
                        // Запит на апі, передача параметрів
                        const response = await axios.get("https://pyinside.mysky.team/getVacation", {
                            params: {
                                pr1: this.excelForm.pr1,
                                pr2: this.excelForm.pr2,
                                companyId: this.excelForm.companyId,
                                token: this.excelForm.token
                            },
                            responseType: 'blob' // обробка файлу
                        });

                        // Створюємо URL для файлу
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'vacation.xlsx'); // Вказуємо ім'я файлу
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } catch (error) {
                        console.error("Помилка при завантаженні файлу:", error);
                    }
                };

                fetchData() // виклик функції
            }
        },
        getdata(page) {
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows та objPages (інформація про сторінки).
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getHolidays(this.objParams).then(result => {
                if (result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #503`);
                }
            })
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModal в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.showModal = false;
            this.getdata(this.objParams.page);
        },
        open(e) {
             /**
                Функція для відкриття модального вікна з інформацією про відгул за його ідентифікатором.
                * @param {Object} e - об'єкт з інформацією про відгул.

                Викликає API для отримання інформації про відгул.
                Результат присвоюється властивості objCard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            apiServe.getRequest(e.holidayId).then(result => {
                if (result.status == 'done') {
                    this.objCard = result.data;
                    this.showModal = true;
                }
            })
        },
        changeFilter(name, value) {
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if (!name.type && value != undefined) {
                if (name == 'pr') {
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        changelimit: function (limit) {
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        mutateType(e, obj) {
            /*
                Функція для застосування перекладу до типу відгулу
            */

            return mutateTypeHoliday(e, obj);
        },
        mutateReason(e) {
            /*
                Функція для застосування перекладу до причини відгулу
            */

            return mutateReasonHoliday(e);
        },
        mutateStatus(e) {
            /*
                Функція для застосування перекладу до статусу відгулу
            */

            return mutateStatusHoliday(e);
        }
    },
    mounted() {
        // Cлухання події saveHolidays для оновлення даних після змін з карткою відгулу.
        this.eventBus.off('saveHolidays');
        this.eventBus.on('saveHolidays', (status) => {
            if (status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        checks() {
            /*
                Отримуємо дані налаштувань з глобального стору.
            */
            return storeS.checks
        },
        perms() {
            /*
                Отримуємо дані налаштувань з глобального стору.
            */
            return storeS.perms
        },
        user() {
            /*
                Отримуємо дані налаштувань з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>